import React from "react";
import { NextPageContext } from "next";
import NextError from "next/error";

export interface ErrorPageProps {
	statusCode: number;
	title: string;
}

export default function ErrorPage({ statusCode, title }: ErrorPageProps) {
	return <NextError statusCode={statusCode} title={title} />;
}

ErrorPage.getInitialProps = async ({ err, res, asPath, pathname, query, AppTree }: NextPageContext) => {
	try {
		const initialProps = await NextError.getInitialProps({ err, res, pathname, query, AppTree });
		if (err) {
			return initialProps;
		}

		const unlikelyToHappenError = new Error(`getInitialProps missing data at path: ${asPath}`);
		console.log(unlikelyToHappenError);
		return initialProps;
	} catch (error) {
		return {};
	}
};
